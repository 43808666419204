@import '../styles/local_variables.css';

:global {
  #root {
    height: 100%;

    #flashlight {
      --xPosition: 50vw;
      --yPosition: 50vh;
    }
    #flashlight:before {
      content: '';
      display: block;
      top: 0;
      z-index: 9999;
      width: 100%;
      height: 100%;
      position: absolute;
      pointer-events: none;
      background: radial-gradient(circle 15em at var(--xPosition) var(--yPosition), rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    }

    .notifications {
      height: 100%;

      .container {
        flex-shrink: 1;
        overflow: hidden;

        .content {
          flex-shrink: 1;
          overflow: hidden;
        }
      }
    }
  }
}

html,
body {
  height: 100%;
}

:global {
  .mapboxgl-map {
    font: inherit;
  }

  .mapboxgl-ctrl-attrib {
    font:
      12px/20px 'Helvetica Neue',
      Arial,
      Helvetica,
      sans-serif;
  }

  .mapboxgl-popup-content {
    border-radius: var(--size-radius-small);
    box-shadow: var(--shadow-elevation-overlay);
  }

  .trimblemaps-map {
    font: inherit;
  }

  .trimblemaps-ctrl-attrib {
    font:
      12px/20px 'Helvetica Neue',
      Arial,
      Helvetica,
      sans-serif;
  }

  .trimblemaps-popup-content {
    border-radius: var(--size-radius-small);
    box-shadow: var(--shadow-elevation-overlay);
  }
}
