.easter-egg {
  position: absolute;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 9998;
  opacity: 0.8;
  transition: all 0.2s ease;
  pointer-events: auto;
}

.easter-egg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.easter-egg:hover {
  transform: scale(1.2);
  opacity: 1;
}

.easter-egg-counter {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: bold;
  z-index: 10000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 14px;
}

.easter-egg-reveal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  z-index: 10001;
  max-width: 400px;
  animation: confetti 1s ease;
}

.easter-egg-reveal h2 {
  color: #ff6b6b;
  margin-top: 0;
}

/* Animation Keyframes */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes flash {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0.2;
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-5px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(5px);
  }
}

@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0);
  }
  100% {
    transform: perspective(400px) rotateY(180deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes wiggle {
  0%,
  100% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(3deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg) scale(1);
  }
  to {
    transform: rotate(360deg) scale(1);
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes grow {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

@keyframes confetti {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

/* Apply animations to each type */
.easter-egg.rotate {
  animation: rotate 8s linear infinite;
}
.easter-egg.bounce {
  animation: bounce 1.5s ease infinite;
}
.easter-egg.flash {
  animation: flash 2s ease infinite;
}
.easter-egg.shake {
  animation: shake 1s ease infinite;
}
.easter-egg.flip {
  animation: flip 3s ease infinite alternate;
}
.easter-egg.pulse {
  animation: pulse 2s ease infinite;
}
.easter-egg.wiggle {
  animation: wiggle 1s ease infinite;
}
.easter-egg.spin {
  animation: spin 6s linear infinite;
}
.easter-egg.float {
  animation: float 3s ease-in-out infinite;
}
.easter-egg.grow {
  animation: grow 2s ease infinite;
}
